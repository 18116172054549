import bgImg1 from '../images/background/bg-1.jpg';
import bgImg2 from '../images/background/bg-3.jpg';
import bgImg3 from '../images/background/bg-4.jpg';
import bgImg6 from '../images/background/bg-6.png';
import img1 from '../images/common/img1.png';

const dataSlider = [
    {
        id: 1,
        title: '"BlockPixels: The portal towards a universe full of pixels"',
        desc : 'Welcome to the vast canvas of the virtual universe, where you become the next pixel alongside Millions of other pixels.',
        bgImg: bgImg6,
        img : img1
    },
       

]

export default dataSlider;