const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
    },
    {
        id: 2,
        name: 'Whitepaper',
        links: '#',
    },
    {
        id: 3,
        name: 'Road Map',
        links: '#tf-roadmap',
    },
    {
        id: 4,
        name: 'Nft',
        links: '/nft',
    },
    {
        id: 5,
        name: 'Generate',
        links: '/generate',
    },
    {
        id: 6,
        name: 'Contact',
        links: '/contact'
    },
    
]

export default menus;