const dataFaq = [
    {
        id: 1,
        title: 'What is a limitless NFT?',
        content: 'The lmitless NFT empowers pixels from its block to generate boundless NFTs, free from any constraints or limitations.',
        show: 'show'
    },
    {
        id: 2,
        title: 'Can we train your A.I?',
        content: 'Absolutely. As pixels continue to generate images, our A.I. will continuously learn, expand its knowledge, and deliver increasingly superior image results.',
        show: 'show'
    },
    {
        id: 3,
        title: 'Why is the team Anonymous?',
        content: 'In our pursuit of providing an unparalleled and limitless experience, we hold privacy as a fundamental principle. Similar to the creators of Bitcoin, we highly esteem privacy and decentralization, ensuring the utmost security and autonomy.',
        show: 'show'
    },
    {
        id: 4,
        title: 'Can I generate images on Telegram?',
        content: 'Certainly! By linking your wallet to your portal account, you gain the freedom to be an unrestrained pixel, even on Telegram. Unlock the potential to generate an infinite array of images, granting you limitless creative possibilities.',
        show: 'show'
    },
]

export default dataFaq;