const dataWork = [
    {
        id: 1,
        numb: '01',
        title: 'Set up your Portal Account',
        text : 'Create a portal account and verify your email',
        active: 'active'
    },
    {
        id: 2,
        numb: '02',
        title: 'Connect wallet',
        text : 'Connect your wallet and link it to your portal account'
    },
    {
        id: 3,
        numb: '03',
        title: "Add your NFT's",
        text : 'Identify and activate the limitless NFT.'
    },
    {
        id: 4,
        numb: '04',
        title: "Limitless Experience",
        text : 'Generate unlimited images...Well done, you have become another important pixel within our universe.'
    },
]
export default dataWork;