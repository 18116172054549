const dataRoadmap = [
    {
        id: 1,
        time: 'Q3 2022 - Phase 1: Research and Planning',
        list: [
            
            {
                text: 'Project Definition: Clearly define the objectives and scope of the AI image generator. Decide what types of images it will generate (e.g., faces, landscapes, abstract art).'
            },
            {
                text: 'Data Collection: Gather a diverse and comprehensive dataset of images relevant to the generators target domain. Ensure the dataset is labeled appropriately if supervised learning is used.'
            },
            {
                text: 'Technology Stack: Select the appropriate AI framework and tools for the project, considering factors such as compatibility, performance, and ease of use.'
            },
        ],
        positon: 'left',
        style: 'normal'
    },
    {
        id: 2,
        time: 'Q4 2022 - Phase 2: Data Preprocessing',
        list: [
            {
                text: 'Data Cleaning: Process and clean the collected dataset to remove duplicates, irrelevant images, and any corrupted data.'
            },

            {
                text: 'Data Augmentation: Increase the dataset size through data augmentation techniques like rotation, flipping, and scaling, to improve the models generalization.'
            }
           
        ],
        positon: 'right',
        style: 'normal'
    },
    {
        id: 3,
        time: 'Q1 2023 - Phase 3: Model Development',
        list: [
            {
                text: 'Model Architecture Selection: Choose an appropriate AI model architecture for the image generator. Options may include Generative Adversarial Networks (GANs), Variational Autoencoders (VAEs), or Transformer-based models.'
            },
            {
                text: 'Model Training: Train the selected model on the preprocessed dataset using suitable optimization techniques, considering GPU/TPU utilization for faster training.'
            },
            {
                text: 'Hyperparameter Tuning: Fine-tune the models hyperparameters to optimize its performance, including learning rate, batch size, and regularization strength.'
            },
            {
                text: 'Validation and Testing: Evaluate the models performance on a separate validation dataset and perform rigorous testing to ensure its stability and quality.s'
            },
        ],
        positon: 'left'
    },
    {
        id: 4,
        time: 'Q2 2023 - Phase 4: User Interface and Integration',
        list: [
            {
                text: 'UI/UX Design: Create an intuitive and user-friendly interface for the image generator, allowing users to customize and control image generation settings.'
            },
            {
                text: 'Integration: Integrate the trained AI model with the UI to enable real-time image generation based on user inputs.'
            }
            
        ],
        positon: 'right'
    },
    {
        id: 5,
        time: 'Q3 2023 - Phase 5: Deployment and Optimization',
        list: [
            {
                text: 'Performance Optimization: Fine-tune the AI model and user interface for optimal performance, considering factors like response time, image quality, and resource utilization.'
            },
            {
                text: 'Error Handling and Logging: Implement proper error handling and logging mechanisms to monitor and diagnose issues during the deployment phase.'
            },
            {
                text: 'Security Measures: Implement security measures to protect the AI model and user data from potential threats.'
            },
           
        ],
        positon: 'left'
    },
    {
        id: 6,
        time: 'Q4 2023 - Phase 6: User Feedback and Iteration',
        list: [
            {
                text: ' Beta Testing: Release the AI image generator in a controlled environment to gather user feedback and identify potential issues.'
            },
            {
                text: 'Feedback Analysis: Analyze user feedback to identify areas of improvement and potential new features.'
            },
            {
                text: 'Continuous Development: Based on user feedback, continuously update and improve the AI image generator with regular releases.'
            },
            
        ],
        positon: 'right'
    },
    {
        id: 7,
        time: 'Q1 2024 - Phase 7: Scaling and Maintenance',
        list: [
            {
                text: 'Scalability Planning: If needed, devise a plan for scaling the image generator to handle increasing user demand.'
            },
            {
                text: 'Monitoring and Maintenance: Implement monitoring tools to track performance, usage patterns, and user behavior, and provide regular maintenance to ensure the systems stability and reliability.'
            }
           
        ],
        positon: 'left'
    }
    
]

export default dataRoadmap;