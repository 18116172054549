import img1 from '../images/common/team1.png';
import img2 from '../images/common/team2.png';
import img3 from '../images/common/team3.png';
import img4 from '../images/common/team4.png';
import img5 from '../images/common/team5.png';
import img6 from '../images/common/team11.png';

const dataTeam = [
    {
        id: 1,
        img: img1,
        name: 'The General',
        position: 'Co-founder & Dev',
        listsocial: [
            
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
           
        ]
    },
    {
        id: 2,
        img: img2,
        name: 'Mr. Technicus',
        position: 'Co-Founder & Dev',
        listsocial: [
            
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
           
        ]
    },
    {
        id: 3,
        img: img3,
        name: 'Kinte',
        position: 'Co-Founder & Dev',
        listsocial: [
            
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
           
        ]
    },
    {
        id: 4,
        img: img4,
        name: 'Yen-Yen',
        position: 'Blockchain Developer',
        listsocial: [
           
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
           
        ]
    },
    {
        id: 5,
        img: img5,
        name: 'Void Walker',
        position: 'Security',
        listsocial: [
           
            {
                id: 3,
                icon: 'fa fa-paper-plane',
                link: '#'
            },
            
        ]
    },
   
]

export default dataTeam;