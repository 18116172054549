const dataAbout = [
    {
        id: 1,
        title: 'Neural Networks',
        desc: 'Our cutting-edge neural networks have been trained on vast libraries of diverse images, ensuring that you have access to an extensive range of styles, themes, and moods. Whether you are an aspiring artist, a seasoned designer, or just someone with an eye for aesthetics, BlockPixels empowers you to bring your ideas to life like never before, by becoming a pixel in an entire digital universe of pixels.',
        class: 'active'
    },
    {
        id: 2,
        title: 'A Very User-Friendly Design',
        desc: 'Our user-friendly interface makes the entire experience smooth and seamless, so you can focus on unleashing your imagination, while the A.I. handles the intricate details.',
    },
    {
        id: 3,
        title: 'Pixels of the Digital Universe',
        desc: 'Welcome to the vast canvas of the virtual universe, where you become the next pixel alongside millions of other pixels. Let your imagination soar and your ideas flow freely. Be inspired by the endless array of possibilities around you. You are a pixel, but together, we are a symphony—a tapestry of pixels creating a legacy that will endure in the boundless realm of the digital universe.',
    },
]

export default dataAbout;